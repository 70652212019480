import { Injectable } from '@angular/core';
import { BaseService } from '../classes/base.service';
import { CacheService } from './cache.service';
import { PageService } from './page.service';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class RefCodeService extends BaseService {
  refCodes: any
  constructor(private http: HttpClient) {
    super();

  }

  getRefCodeByTypeSorted(type: string, order: 'asc' | 'desc' = 'asc'): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('./dummy.json').subscribe(
        (data: any) => {
          this.refCodes = data.refcodes;
          const refType = this.refCodes[type];

          if (refType) {
            const sortedData = [...refType].sort((a, b) => {
              return order === 'asc' ? a.order - b.order : b.order - a.order;
            });
            resolve(sortedData);
          } else {
            resolve(null);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

}
